/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                $('body, html').scrollTop(0);
                $('.load-overlay').velocity('fadeOut');

                $('.menu-item-has-children > i.fa').click(function() {
                  currentUl = $(this).parent();
                  $('.menu-item-has-children').not(currentUl).removeClass('active');
                  $(this).parent().toggleClass('active');
                  $(this).toggleClass('fa-caret-right');
                  $(this).toggleClass('fa-caret-down');
                });

                $('#searchOpen').click(function() {
                  $('#mobileSearch').addClass('active');
                });

                $('#searchClose').click(function() {
                  $('#mobileSearch').removeClass('active');
                  return false;
                });

                $('#close_overlay').click(function() {
                  $('.overlay_actie').velocity('fadeOut');
                });

                $('.zoom').zoom({'magnify': 1.5});
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
                $('.liquid').imgLiquid();
                $('.variation select').customSelect();
                $('.orderby').customSelect();
                $('.wppp-select').customSelect();

                $('.mobileNav').click(function(e){
                  if($(this).outerWidth() > 550){
                    $('.mobileNav').velocity('fadeOut');
                    $('html').removeClass('menu-open');
                  }
                });
                
                $('.variations_form').submit(function(e){
                    if($('.woocommerce-variation-add-to-cart').hasClass('woocommerce-variation-add-to-cart-disabled')) {
                        e.preventDefault();

                        $('.customSelect').addClass('false');
                    } else {
                        $('.customSelect').removeClass('false');
                    }
                });

                $('.wfg-checkbox').prop('checked', true).css('display', 'none');
                $('.wfg-add-gifts').click(function(){
                    $('.wfg-popup').velocity('fadeOut');
                });


                $( 'body' ).on( 'updated_checkout', function() {
                    var val = $('input.shipping_method:checked').val();

                    console.log(val);

                    if(val == 'local_pickup:3') {
                        $('#ship-to-different-address').hide();
                    } else {
                        $('#ship-to-different-address').show();
                    }
                });

                $('.mobile_filters').click(function(e){
                  $('.sidebar').toggle();
                  $(this).toggleClass('open');
                });

                $('.mobileNav .menuwrap').click(function(e){
                  e.stopPropagation();
                });

                $('.mobileNav .top_nav .menu-item-has-children').click(function(e){
                    if(!$(this).hasClass('open')) {
                        e.preventDefault();
                        $(this).addClass('open');

                        $('.top_nav li').hide();
                        $('.back_home').show();
                        $(this).show();
                        $(this).find('ul.sub-menu').show();
                    }
                });

                $('.navToggle').click(function(){
                    $('.mobileNav').velocity('fadeIn');
                    $('html').addClass('menu-open');
                });

                $('#closeMenu').click(function(e){
                    $('.mobileNav').velocity('fadeOut');
                    $('html').removeClass('menu-open');
                });

                $('.mobileNav .top_nav .back_home').click(function(e){
                    $(this).hide();
                    $('.top_nav li').show();
                    $('.top_nav li').removeClass('open');
                    $('.top_nav ul.sub-menu').hide();
                });

                $('.media-large-slider').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: false,
                    fade: true,
                    asNavFor: '.media-thumbnail-slider'
                });

                $('.media-thumbnail-slider').slick({
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    asNavFor: '.media-large-slider',
                    dots: false,
                    focusOnSelect: true,
                    arrows: true,
                    prevArrow: '<button class="PrevArrow"></button>',
                    nextArrow: '<button class="NextArrow"></button>'
                });

                function slickify() {
                    $('.usps').slick({
                        arrows: false,
                        dots: false,
                        infinite: true,
                        draggable: false,
                        accessibility: false,
                        autoplay: true,
                        autoplaySpeed: 3000,
                        responsive: [{
                            breakpoint: 9999,
                            settings: "unslick"
                        }, {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }]
                    });
                }

                slickify();
                $(window).resize(function() {
                    var $windowWidth = $(window).width();
                    if ($windowWidth < 786) {
                        slickify();
                    }
                });

                $('.footer_usp_slider').slick({
                    arrows: false,
                    dots: false,
                    infinite: true,
                    draggable: false,
                    accessibility: false,
                    slidesToShow: 2,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }]
                });

                var formValue = $('.price_wrap .price').html();
                $('form.cart').on( 'change', '.variations select', function( event ) {
                    $val=$(this).val();

                    setTimeout(function(){
                        if($val.length > 0) {
                            var curVal = $('.woocommerce-variation-price .price').html();
                            $('.price_wrap .price').html(curVal);
                        } else {
                            $('.price_wrap .price').html(formValue);
                        }
                    }, 100);
                    
                    $(this).children('option').each(function(){
                        $childVal = $(this).val(); 
                        if ( $childVal == $val ) {
                            $(this).attr('selected', true);
                        } else {
                            $(this).removeAttr('selected');
                        }   
                    });

                    $(this).trigger('render');                    
                });

                function sizes() {
                    var t = 0; // the height of the highest element (after the function runs)

                    $("ul li.product").each(function() {
                        div = $(this);
                        div.css('height', 'auto');

                        if (div.outerHeight() > t) {
                            t = div.outerHeight();
                        }
                    });

                    $("ul li.product").each(function() {
                        $(this).css('height', t);
                    });

                    var n = 0; // the height of the highest element (after the function runs)

                    $(".row .gender").each(function() {
                        div = $(this);
                        div.css('height', 'auto');

                        if (div.outerHeight() > n) {
                            n = div.outerHeight();
                        }
                    });

                    $(".row .gender").each(function() {
                        $(this).css('height', n);
                    });

                    var x = 0; // the height of the highest element (after the function runs)

                    $(".gender .block").each(function() {
                        div = $(this);
                        div.css('height', 'auto');

                        if (div.outerHeight() > x) {
                            x = div.outerHeight();
                        }
                    });

                    $(".gender .block").each(function() {
                        $(this).css('height', x);
                    });
                }
                sizes();

                $(window).resize(function() {
                    sizes();
                });

                $('.product_slider').slick({
                    dots: false,
                    arrows: false,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    draggable: false,
                    accessibility: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    responsive: [{
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1
                            }
                        }, {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }

                    ]
                });

                $('.overlay .more').click(function() {
                    var status = $(this).attr('data-status');
                    var content = $(this).parent().parent();
                    if (status === 'close') {
                        $(this).attr('data-status', 'open');
                        $(this).html('Toon minder <i class="fa fa-angle-double-up" aria-hidden="true"></i>');
                        content.css('height', 'auto');
                        content.css('height', content.outerHeight() + 25);
                    } else {
                        $(this).attr('data-status', 'close');
                        $(this).html('Toon meer <i class="fa fa-angle-double-down" aria-hidden="true"></i>');
                        content.css('height', '170px');
                    }

                });

                $('.cat_hide h3').click(function(){
                    var status = $(this).parent().attr('data-status');

                    if(status === 'close') {
                        $('.cat_hide ul li').show();
                        $(this).parent().attr('data-status', 'open');
                    } else {
                        $('.cat_hide ul li').hide();
                        $('.cat_hide ul li.cur_cat').show();
                        $(this).parent().attr('data-status', 'close');
                    }
                });
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
                $('.header_slider').slick({
                    dots: true,
                    arrows: false,
                    infinite: true,
                    autoplay: false,
                    autoplaySpeed: 3000,
                    draggable: true,
                    adaptiveHeight: true,
                    accessibility: true
                });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'veelgestelde_vragen': {
            init: function() {
                $("div.tab").click(function() {
                    $(this).toggleClass("open");
                    $(this).find(".inner").slideToggle();
                });
            }
        },
        'single_wall_of_fame': {
            init: function() {
                function sizes() {
                    var width = $('.header_badge h3').width();
                    var marginLeft = width / 2;
                    $('.header_badge h3').css('margin-left', -marginLeft);
                }
                sizes();

                $(window).resize(function() {
                    sizes();
                });
            }
        },
        'winkelmand': {
          init: function() {
            var checkoutLink = $('.checkout-button').href; // get checkout link

            $('#checkout-modalTrigger').click(function() {
                var specialOfferModal = sessionStorage.getItem('specialOfferModal'); // check if modal was triggered earlier during this session

                if( specialOfferModal === 'triggered') {
                  location.href = checkoutLink; // if it was triggered, open checkout page instead of showing the modal
                } else {
                  $('#checkout-modal').removeClass('hidden'); // show modal
                  sessionStorage.setItem('specialOfferModal', 'triggered'); // set specialOfferModal to triggered in sessionStorage
                  return false; // prevent link redirecting user to checkout
                }
            });
          }
        }
    };


    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
